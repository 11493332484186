<script>
import Layout from "../../layouts/main";
import NotificationServiceApi from "@/service/api/notifications";
export default {
  components: { Layout },
  data() {
    return {
      msg: {
        title: null,
        message: null,
        topic: null,
      },
      sending: false,
    };
  },
  methods: {
    submitHandler(data) {
      this.brodCastMessage(data);
    },
    brodCastMessage(data) {
      this.sending = true;
      NotificationServiceApi.brodcastTopicPushNotification(data)
        .then(() => {
          this.actionMessage("Send successfully");
          this.msg.title = null;
          this.msg.message = null;
          this.msg.topic = null;
        })
        .catch((err) => {
          console.log(err);
          this.actionMessage("Broadcast failed", "danger");
        })
        .finally(() => {
          this.sending = false;
        });
    },
    actionMessage(message, variant = "success") {
      this.$bvToast.toast(message, {
        title: "Notification Broadcasting",
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <b-card class="pushNotificationTopic">
      <FormulateForm v-model="msg" @submit="submitHandler">
        <FormulateInput
          type="text"
          name="title"
          label="Title"
          placeholder="Title here!"
          validation="required"
        />
        <FormulateInput
          type="text"
          name="topic"
          label="Topic"
          placeholder="Topic here!"
          validation="required"
        />
        <FormulateInput
          type="textarea"
          name="message"
          label="Message"
          placeholder="Message here!"
          validation="required|max:100,length"
        />
        <FormulateInput type="submit">
          <span v-if="sending">
            Broadcasting
            <b-spinner
              type="grow"
              small
              class="ml-1"
              variant="danger"
              role="status"
            ></b-spinner>
          </span>
          <span v-else>
            Broadcast
          </span>
        </FormulateInput>
      </FormulateForm>
    </b-card>
  </Layout>
</template>

<style>
.pushNotificationTopic {
  min-height: 500px;
  padding: 50px;
}
.pushNotificationTopic textarea {
  height: 250px;
}
</style>
